import React from 'react'
import MediaItem from '../MediaItem'
import { GatsbyImageProps } from 'gatsby-plugin-image'
import cx from 'classnames'
import diamondIcon from '@/images/video-diamond.svg'
import SVG from '../SVG'
import Link from '../Link'
import { useInView } from 'react-intersection-observer'

export enum BTN_COLOR {
	MAGENTA = `magenta`,
	TEAL = `teal`,
	DARK = `dark`,
	LIGHT = `light`,
}

interface IButtonProps {
	color: BTN_COLOR | string
	icon?: {
		isImage: boolean
		format: string
		gatsbyImageData: GatsbyImageProps
		url: string
	}
	text: string
	hasIcon?: boolean
	innerBtnClassName?: string
	containerClassName?: string
	clickHandler?: any // TODO: typing
	hoverColor: BTN_COLOR | string
	borderHoverColor?: BTN_COLOR | string
	disabled?: boolean
	isExternal?: boolean
	externalLink?: string
	link?: string
	large?: boolean
}

const Button: React.FC<IButtonProps> = ({
	color = BTN_COLOR.MAGENTA,
	hoverColor = BTN_COLOR.TEAL,
	borderHoverColor = BTN_COLOR.DARK,
	icon,
	text,
	hasIcon,
	innerBtnClassName,
	containerClassName,
	clickHandler = () => true,
	disabled = false,
	isExternal,
	externalLink,
	link,
	large = false,
}) => {
	const { ref, inView, entry } = useInView({
		/* Optional options */
		threshold: 0.3,
		triggerOnce: true,
	})

	const classes = cx(
		`btn-clip btn${
			`--` + color
		} flex w-full justify-center items-center relative px-10 h-14 text-center absolute overflow-visible transition-all duration-500 btn-bg-hover${
			disabled ? `--` + color : `--` + hoverColor
		} ${large ? `text-2xl` : `text-base`}`,
		innerBtnClassName
	)

	const containerClasses = cx(
		` ${
			inView ? `bottom-left-corner-fade-in` : `opacity-0`
		} relative py-2 mb-6 cursor-pointer group  ${
			disabled ? `cursor-not-allowed` : ``
		}`,
		containerClassName
	)

	const calculateBorderColor = (color: BTN_COLOR) => {
		switch (color) {
			case BTN_COLOR.MAGENTA:
				return `light2`
			case BTN_COLOR.LIGHT:
				return `light2`
			case BTN_COLOR.DARK:
				return `black5`
			case BTN_COLOR.TEAL:
				return `light2`
			default:
				return `light2`
		}
	}

	const renderBaseButton = () => {
		return (
			<div className={containerClasses} onClick={clickHandler} ref={ref}>
				<div
					className={` ${
						inView ? `fade-in-top-border` : `opacity-0`
					} absolute h-[1px] w-[calc(100%-1.5rem)] right-[calc(50%-calc((100%-1.5rem)/2))] bg-${calculateBorderColor(
						color
					)} btn-bg-hover${
						`--` + borderHoverColor
					} transition-all duration-500 top-1`}
				>
					<div
						className={` btn-left-border-clip group-hover:btn-border-clip-hover absolute h-[1px] w-[36.5px] bg-${calculateBorderColor(
							color
						)} btn-bg-hover${
							`--` + borderHoverColor
						} transition-all duration-500  top-[15.5px] -left-[27.5px] -rotate-[60deg]`}
					></div>
					<div
						className={` btn-right-border-clip group-hover:btn-border-clip-hover absolute h-[1px] w-[36.5px] bg-${calculateBorderColor(
							color
						)} btn-bg-hover${
							`--` + borderHoverColor
						} transition-all duration-500  top-[15.5px] -right-[27.5px] rotate-[60deg]`}
					></div>
				</div>
				<div
					className={` ${
						inView ? `fade-in-bottom-border` : `opacity-0`
					} absolute h-[1px] w-[calc(100%-1.5rem)] right-[calc(50%-calc((100%-1.5rem)/2))] bg-${calculateBorderColor(
						color
					)} btn-bg-hover${
						`--` + borderHoverColor
					}  transition-all duration-500 bottom-1`}
				>
					<div
						className={`btn-left-border-clip group-hover:btn-border-clip-hover absolute h-[1px] w-[36.5px] bg-${calculateBorderColor(
							color
						)} btn-bg-hover${
							`--` + borderHoverColor
						}  transition-all duration-500 bottom-[15.5px] -left-[27.5px] rotate-[60deg]`}
					></div>
					<div
						className={`btn-right-border-clip group-hover:btn-border-clip-hover absolute h-[1px] w-[36.5px] bg-${calculateBorderColor(
							color
						)} btn-bg-hover${
							`--` + borderHoverColor
						} transition-all duration-500 bottom-[15.5px] -right-[27.5px] -rotate-[60deg]`}
					></div>
				</div>
				<SVG
					src={diamondIcon}
					className={`fill-${
						color === BTN_COLOR.DARK ? `light2` : `black5`
					} absolute left-1.5 h-2 top-8 z-20 group-hover:-left-2.5 btn-border-hover${
						`--` + borderHoverColor
					} transition-all duration-500 delay-200`}
				/>
				<SVG
					src={diamondIcon}
					className={`fill-${
						color === BTN_COLOR.DARK ? `light2` : `black5`
					} absolute right-1.5 h-2 top-8 z-20 group-hover:-right-2.5 btn-border-hover${
						`--` + borderHoverColor
					} transition-all duration-500 delay-200`}
				/>
				<div className={classes}>
					<p
						className={`font-evercore flex justify-center items-center uppercase text-center w-full h-full whitespace-nowrap ${
							large ? `text-4xl font-medium tracking-widest px-16` : `text-xl`
						}`}
					>
						{text}
						{hasIcon && icon && (
							<MediaItem
								gatsbyImageData={icon.gatsbyImageData}
								isImage={icon.isImage}
								format={icon.format}
								svgSrc={icon.url}
								className="h-4 ml-2"
							/>
						)}
					</p>
				</div>
			</div>
		)
	}

	if (externalLink && isExternal) {
		return (
			<a
				href={externalLink}
				onClick={(e) => {
					if (externalLink.includes(`store.evercoreheroes.com`)) {
						e.preventDefault()
						window.open(externalLink, `_blank`)
						try {
							gsght(`send`, `storefront_click`)
						} catch (e) {
							console.error(e)
						}
					}
				}}
				target="_blank"
				rel="noreferrer"
			>
				{renderBaseButton()}
			</a>
		)
	} else if (link) {
		return <Link to={link}>{renderBaseButton()}</Link>
	} else {
		return <>{renderBaseButton()}</>
	}
}

export default Button
