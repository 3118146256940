import React from 'react'
import cx from 'classnames'

interface IStructuredListProps {
	node: { type: string; style: string; children: any[] }
	adapter: {
		renderText: () => void
		renderNode: () => void
		renderFragment: () => void
	}
	key: any
	children: React.ReactNode[]
	className: string
}

const StructuredList: React.FC<IStructuredListProps> = ({
	node,
	adapter,
	key,
	children,
	className,
}) => {
	return (
		<div className={cx(className)}>
			<ul>{children}</ul>
		</div>
	)
}

export default StructuredList
