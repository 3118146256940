import Blade from '@/components/Blade'
import Button, { BTN_COLOR } from '@/components/Button'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import Heading from '@/components/Heading/Heading'
import StructuredList from '@/components/Structured/StructuredList'
import { ContextProvider } from '@/context'
import CookieBanner from '@/globals/CookieBanner'
import Meta from '@/hoc/Meta'
import { CONFIG_COLORS } from '@/types'
import {
	isHeading,
	isList,
	isParagraph,
	renderRule,
	Node,
} from 'datocms-structured-text-utils'
import { graphql } from 'gatsby'
import React from 'react'
import {
	StructuredText,
	StructuredTextDocument,
	StructuredTextGraphQlResponse,
} from 'react-datocms'

interface IPolicyProps {
	data: {
		policy: {
			id: string
			locale: string
			slug: string
			pageName: string
			seo: any // TODO
			policyContent:
				| StructuredTextGraphQlResponse<any, any> // TODO
				| StructuredTextDocument
				| Node
				| null
				| undefined
		}
		configs: {
			edges: {
				node: {
					id: string
					locale: string
					pageSlug: string
					pageName: string
				}
			}[]
		}
	}
}

const Policy: React.FC<IPolicyProps> = ({ data, location, pageContext }) => {
	return (
		<main>
			<ContextProvider>
				<Meta {...{ seo: pageContext?.node?.seo }} />

				<Header
					locale={data.configs.edges[0].node.locale}
					pathname={location.pathname}
				/>

				<Blade className="bg-light1">
					<section className=" overflow-hidden">
						<div className="w-11/12 mx-auto max-w-[1060px] relative z-30 mb-24">
							<StructuredText
								data={data.policy.policyContent}
								customRules={[
									renderRule(isHeading, ({ node, children, key }) => (
										<Heading
											{...{
												key,
												type: node.level,
												className: `${
													node.level === 1
														? `mb-6 md:mb-10 mt-6 md:mt-10 !text-3xl !lg:text-4xl !xl:text-5xl`
														: node.level === 2
														? `mb-4 md:mb-10 mt-4 md:mt-10 !text-2xl !lg:text-3xl !xl:text-4xl`
														: `mb-4 md:mb-10 mt-4 md:mt-10 !text-xl !lg:text-2xl !xl:text-3xl`
												}`,
												color: CONFIG_COLORS.BLACK_2,
											}}
										>
											{children}
										</Heading>
									)),
									renderRule(isParagraph, ({ node, children, key }) => (
										<p
											{...{
												key,
												className: `mb-7`,
											}}
										>
											{children}
										</p>
									)),
									renderRule(isList, ({ node, children, adapter, key }) => (
										<StructuredList
											{...{
												node,
												adapter,
												key,
												className: ` w-11/12 mx-auto mb-12 lg:mb-16`,
											}}
										>
											{children}
										</StructuredList>
									)),
								]}
							/>
						</div>
						<div className="flex justify-between w-11/12 mx-auto max-w-[1060px] relative z-30 border-t border-slateGrey pt-4">
							<Button
								{...{
									to: `/`,
									color: BTN_COLOR.DARK,
									text: `Back to Home`,
									hoverColor: BTN_COLOR.MAGENTA,
								}}
							></Button>
							<Button
								{...{
									clickHandler: () =>
										window.scrollTo({
											top: 0,
											left: 0,
											behavior: `smooth`,
										}),
									color: BTN_COLOR.TEAL,
									text: `Back to top`,
									hoverColor: BTN_COLOR.DARK,
								}}
							></Button>
						</div>
					</section>
				</Blade>
				<Footer
					locale={data.configs.edges[0].node.locale}
					pathname={location.pathname}
				/>
				<CookieBanner />
			</ContextProvider>
		</main>
	)
}

export default Policy

export const query = graphql`
	query getPolicyPage($id: String, $locale: String) {
		policy: datoCmsPolicyPage(id: { eq: $id }, locale: { eq: $locale }) {
			id
			seo {
				description
				title
				twitterCard
				image {
					url
				}
			}
			pageName
			slug
			policyContent {
				value
				blocks
			}
		}
		configs: allDatoCmsPage {
			edges {
				node {
					id
					locale
					pageSlug
					pageName
				}
			}
		}
	}
`
