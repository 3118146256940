import cx from 'classnames'
import React from 'react'

interface IBladeProps {
	className?: string
	children: React.ReactNode
}

const Blade: React.FC<IBladeProps> = ({ className, children }) => {
	const classes = cx(`px-6 xs:px-10 md:px-16 lg:px-28 py-20 md:py-20`, className)

	return <section className={classes}>{children}</section>
}

export default Blade
