import { IGlobalConfig } from '@/types'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import SVG from 'react-inlinesvg'
import Link from '../Link'
import MediaItem from '../MediaItem'
import navItemActive from '@/images/nav-selected.svg'
import arrowIcon from '@/images/arrow-light.svg'
import diamondIcon from '@/images/video-diamond.svg'

interface IFooterProps {
	locale: string
	pathname: string
}

const Footer: React.FC<IFooterProps> = ({ locale, pathname }) => {
	const { configs } = useStaticQuery(
		graphql`
			query getFooterConfig {
				configs: allDatoCmsGlobalConfig {
					nodes {
						locale
						logo {
							isImage
							format
							gatsbyImageData(placeholder: BLURRED)
							url
						}
						copyrightText
						footerNavigationLinks {
							... on DatoCmsPage {
								id
								pageSlug
								pageName
							}
						}
						policyPagesLinks {
							pageName
							slug
						}
						platforms {
							namedIcon {
								gatsbyImageData(placeholder: BLURRED)
								isImage
								format
								url
							}
						}
						socials {
							id
							name
							link
							icon {
								isImage
								format
								gatsbyImageData(placeholder: BLURRED)
								url
							}
						}
					}
				}
			}
		`
	)
	// find config relevant to current locale
	const currentLocaleConfig: IGlobalConfig = configs?.nodes?.find(
		({ locale: _locale }: { locale: string }) => _locale === locale
	)
	const navLinks = currentLocaleConfig.footerNavigationLinks
	const socials = currentLocaleConfig.socials
	const platforms = currentLocaleConfig.platforms
	const logo = currentLocaleConfig.logo
	const policyPagesLinks = currentLocaleConfig.policyPagesLinks
	const copyrightText = currentLocaleConfig.copyrightText

	const handleBackToTop = () => {
		if (typeof window !== `undefined`) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: `smooth`,
			})
		}
	}
	// const handleBackToTop = () => {
	// 	if (typeof document !== `undefined`) {
	// 		const signUpElement = document.getElementById(`character-hero-blade`)
	// 		signUpElement?.scrollIntoView({
	// 			behavior: `smooth`,
	// 			block: `end`,
	// 			inline: `nearest`,
	// 		})
	// 	}
	// }

	return (
		<nav className="flex flex-col justify-center relative md:bg-black3 z-70">
			<div className="bg-black3 px-10 py-10 md:px-24">
				<div className="w-full h-fit">
					<div className="w-full h-full">
						<div
							className={`flex justify-center md:justify-between items-center w-full h-14 mb-10`}
						>
							<MediaItem
								gatsbyImageData={logo.gatsbyImageData}
								isImage={logo.isImage}
								format={logo.format}
								svgSrc={logo.url}
								className=" h-full hidden md:block fill-white"
								mediaItemClasses="fill-light2 w-40"
							/>
							<div
								className="flex justify-center items-center cursor-pointer group"
								onClick={handleBackToTop}
							>
								<SVG
									className={`w-10 h-10 mr-4 fill-light2 text-light3 group-hover:fill-black1 group-hover:opacity-100 transition-all duration-300`}
									src={arrowIcon}
								/>
								<p className="uppercase italic text-light2 md:text-lg group-hover:text-black1 transition-all duration-300">
									Back to the top
								</p>
							</div>
						</div>

						<div className="w-full h-full">
							<div className="h-[1px] w-[calc(50%-0.75rem)] md:w-[calc(50%-6.75rem)] bg-black1 absolute left-0 md:left-24 mb-6" />
							<div className="h-[1px] w-[calc(50%-0.75rem)] md:w-[calc(50%-6.75rem)] bg-black1 absolute  right-0 md:right-24 mb-6" />
							<SVG
								src={diamondIcon}
								className="fill-black1 h-2 relative left-[calc(50%-0.175rem)] -top-[0.25rem]"
							/>
						</div>
					</div>
				</div>
				<MediaItem
					gatsbyImageData={logo.gatsbyImageData}
					isImage={logo.isImage}
					format={logo.format}
					svgSrc={logo.url}
					className="md:hidden h-full mb-8 block fill-white max-w-[10rem] md:max-w-xs mx-auto mt-14"
					mediaItemClasses="fill-light2"
				/>
				<div className="text-center md:flex">
					{navLinks.map((navLink, i) => {
						const slug = navLink.pageSlug === `home` ? `` : navLink.pageSlug

						return (
							<div
								key={`navLink-${i + 1}`}
								className=" text-center h-full my-4 md:mr-12"
							>
								<Link
									to={slug === `home` ? `/` : `/` + slug}
									className={` text-light2 text-center text-sm ${
										`/` + slug === pathname ? `text-teal` : ``
									}`}
								>
									{navLink.pageName}
								</Link>
							</div>
						)
					})}
				</div>
				<div className="flex flex-col md:flex-row items-center h-full">
					<p className="text-black1  text-xs text-center md:text-left mt-6">
						{copyrightText}
					</p>
					<div className="flex justify-center items-center md:justify-end w-full mt-6">
						{socials.map((social, i) => (
							<a
								href={social.link}
								key={i}
								className={`h-4 ${i + 1 < socials.length ? `mr-6` : ``}`}
								target="_blank"
								rel="noreferrer"
							>
								<MediaItem
									gatsbyImageData={social.icon.gatsbyImageData}
									isImage={social.icon.isImage}
									format={social.icon.format}
									svgSrc={social.icon.url}
									className="h-full w-full z-1 fill-black1 hover:fill-light2 transition-all duration-300"
								/>
							</a>
						))}
					</div>
				</div>
			</div>
			<div className="clip-footer-nav bg-light2 py-4 px-10 text-center block md:flex md:flex-row-reverse md:justify-between md:items-center md:w-11/12 md:mx-auto">
				<div className="md:flex">
					<div className="text-black5 py-2 md:py-0 md:px-2 lg:px-3 text-sm cursor-pointer">
						<a
							href="mailto://press@vela.games"
							className={`text-black5 text-center text-sm`}
						>
							Press Contact Email
						</a>
					</div>
					{policyPagesLinks.map((link, i) => (
						<div
							key={i}
							className="text-black5 py-2 md:py-0 md:px-2 lg:px-3 text-sm cursor-pointer"
						>
							<Link
								to={`/` + link.slug}
								className={` text-black5 text-center text-sm ${
									`/` + link.slug === pathname ? `text-teal` : ``
								}`}
							>
								{link.pageName}
							</Link>
						</div>
					))}
				</div>
				<div className="flex justify-between items-center flex-wrap h-auto w-full md:w-2/5 mb-8 mt-6 md:my-0">
					{platforms.map((platform, i) => (
						<MediaItem
							gatsbyImageData={platform.namedIcon.gatsbyImageData}
							isImage={platform.namedIcon.isImage}
							format={platform.namedIcon.format}
							svgSrc={platform.namedIcon.url}
							key={i}
							className="z-10 w-1/5 fill-black1 opacity-30 max-h-[30px]"
							mediaItemClasses="max-h-[30px]"
						/>
					))}
				</div>
			</div>
		</nav>
	)
}

export default Footer
